import React from "react"
import StaticSectionHeading from "../static-section-heading/static-section-heading"
import StaticPageMaxWithWrapper from "../static-page-max-width-wrapper/static-page-max-width-wrapper"
import Styles from "./static-section-final.module.scss"
import Button from "../button/button"

const moreItems = ['No credit card required', 'Enterprise-level security', 'GDPR compliant']

const StaticSectionFinal = ({ children, ...props }) => {
  return (
    <section className={Styles.final} {...props}>
      <StaticPageMaxWithWrapper>
        <div className={Styles.gradient}>
          <StaticSectionHeading>
            <div className={Styles.headingContainer}><h2>Intelligent, connected multimedia localisation.</h2></div>
            <div className={Styles.copyButtonContainer}>
              <p>CaptionHub is an AI-powered collaborative platform for multilingual subtitling and voiceover.</p>
              <div className={Styles.buttonContainer}>
                <Button link={"/demo"}>See pricing</Button>
                <Button link={"/pricing-tiers"} type={"Solid"}>Book a demo</Button>
              </div>
            </div>
          </StaticSectionHeading>

          {/* <div className={Styles.more}>
            <div className={Styles.more__items}>
              {
                moreItems.map((item, index) => (
                  <div className={Styles.more__item} key={index}>
                    <div className={Styles.more__icon}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 4.5L6.75 12.75L3 9" stroke="#00DDF1" stroke-width="2" stroke-linecap="square" />
                      </svg>
                    </div>
                    {item}
                  </div>)
                )
              }
            </div>
          </div> */}
        </div>
      </StaticPageMaxWithWrapper >
    </section >
  )
}

export default StaticSectionFinal
